import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { YandexTableau } from '@components/Layouts/YandexTableau';
import Layout from '../../components/Layouts/Layout';
import { Header } from '../../components/Blocks/Header';
import SEO from '../../components/Layouts/SEO';
import { SchemaOrgOrganization } from '../../components/Layouts/SchemaOrg/SchemaOrgOrganization';
import pageStyles from '../page.module.scss';
import styles from './index.module.scss';
import { usePosts } from '../../hooks/usePosts';

export function Head() {
  return (
    <>
      <SEO title="Блог" description="Блог" />
      <YandexTableau />
      <SchemaOrgOrganization />
    </>
  );
}

function HelpPage() {
  const items = usePosts();

  return (
    <Layout>
      <div className={styles.wrapper}>
        <div className={pageStyles.header}>
          <Header>Блог</Header>
        </div>
        <div className={styles.menu}>
          {items.map((item) => (
            <Link key={item.key} className={styles.menuItem} to={item.url}>
              <GatsbyImage
                alt={item.title}
                image={item.image.localFile.childImageSharp.gatsbyImageData}
                className={styles.image}
              />
              <div className={styles.info}>
                <h3 className={styles.title}>{item.title}</h3>
                <p className={styles.text}>{item.description}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  );
}

export default HelpPage;
